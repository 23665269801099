.forgot-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden; /* Prevent scrolling */
}

.forgotPasswordCard {
    width: 35rem;
    margin: auto;
    border-radius: 0.75rem !important;
    border: none;
}

.heading {
    color: #000;
    text-align: left;
    margin-top: 20px;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    font-weight: 700px !important;
}

.forgotPassword-input {
    margin-top: 10px;
    width: 100% !important;
    height: 50px;
    border-radius: 10px;
    border: 1px solid #CCDDE8 !important;
    margin-bottom: 1rem;
}

.back-icon{
    cursor: pointer;
}
