.chatBodyCard{
    box-shadow: rgb(145 158 171 / 20%) 0px 3px 1px -2px, rgb(145 158 171 / 14%) 0px 2px 2px 0px, rgb(145 158 171 / 12%) 0px 1px 5px 0px;
    margin-top: 20px !important;
    height: 570px !important;
    border-radius: 8px !important;
    overflow-y: auto;
}
.cht-body {
    height: 400px !important;
    overflow-y: scroll;
    padding-right:10px
}
.cht-body::-webkit-scrollbar-thumb {
    background: #ddd !important;
    border-radius: 10px;
}
 .chatBodyCard::-webkit-scrollbar {
    display: none;
}
.cht-body::-webkit-scrollbar {
    width: 5px;

  }
.cht-body, .chatBodyCard {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none !important;  /* Firefox */
}
.chatFooterCard{
    box-shadow: rgb(145 158 171 / 20%) 0px 3px 1px -2px, rgb(145 158 171 / 14%) 0px 2px 2px 0px, rgb(145 158 171 / 12%) 0px 1px 5px 0px;
    margin-top: 20px !important;
    height: 150px!important;
    border-radius: 8px !important;
}
.leaveBtn{
    background: red !important;
    color: rgb(255, 255, 255);
    padding-left: 5px;
    width: 9%;
    border: none !important;
    margin-right: 15px;
    cursor: pointer;
}
.message__recipient {
    background-color: #2c616d;
    width: 250px;
    padding: 10px;
    border-radius: 10px;
    font-size: 15px;
    color:#fff;
  }
  .message__sender {
    background-color: #21C48A;
    max-width: 250px;
    padding: 10px;
    border-radius: 10px;
    margin-left: auto;
    font-size: 15px;
    color: #fff;
  }

  @media only screen and (min-width: 768px) {
    .chatBodyCard{
      height: 460px !important;
    }
    .cht-body{
      height: 320px !important;
    }
    .leaveBtn{
    width: 15%;
    }
}

@media only screen and (min-width : 1024px){
  .chatBodyCard{
    height: 540px !important;
  }
  .cht-body{
    height: 400px !important;
  }
}

@media only screen and (min-width : 1440px){
  .chatBodyCard{
    height: 800px !important
  }
.cht-body {
    height: 670px !important;
}
}

.fade.chatModal.modal.show{
 padding-left: 0px !important;
}