.chatCard{
    box-shadow: rgb(145 158 171 / 20%) 0px 3px 1px -2px, rgb(145 158 171 / 14%) 0px 2px 2px 0px, rgb(145 158 171 / 12%) 0px 1px 5px 0px;
margin-bottom: 20px !important;
background-color: #fff;
}

.chatSidebar{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    margin-right: 0 !important;
    margin-left: 0 !important;
    padding-left: 0px !important;
    /* background-color: #151528 !important; */
    background-color: #fff;
    box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
    /* border: 2px Solid #f8c537; */
    border-top: none;
    min-height: 600px;
    }

  