/* .modal-content>#loader {
    background: transparent !important;
} */

.Loader-modal .modal-content {
    background-color: transparent !important;
    border: none;
}
.Loader-modal .modal-dialog {
    
    text-align: center !important;
}