.videoCard{
    box-shadow: rgb(145 158 171 / 20%) 0px 3px 1px -2px, rgb(145 158 171 / 14%) 0px 2px 2px 0px, rgb(145 158 171 / 12%) 0px 1px 5px 0px;
    margin-bottom: 20px !important;
}

.videoCardBtn{
    border: none;
    background-color:green !important; 
    color: #fff;
}

.videoCardBtn:hover{
    border: none;
    background-color:green !important; 
    color: #fff; 
}
.videoCardBtn:active{
    border: none;
    background-color:green !important; 
    color: #fff;
}

@media only screen and (min-width: 768px) {
    .videoCardContainer{
        justify-content: center;
        overflow-y: scroll;
        height: 500px;
    }
}

@media only screen and (min-width : 1024px){
    .videoCardContainer{
        justify-content: center;
        overflow-y: scroll;
        height: 490px;
    }
    .vidCol{
        width:50% !important
    }
}

@media screen and (max-width:1439px ) and (min-width: 1025px) {
    .videoCardContainer{
        justify-content: center;
        overflow-y: auto;
        height: 400px;
    }
    .vidCol{
        width:33% !important
    }
  }
    @media only screen and (min-width: 1440px){
        .videoCardContainer{
            justify-content: center;
            overflow-y: scroll;
            height: 500px;
        }
        .vidCol{
            width:33% !important
        }
    }

@media only screen and (min-width:1792px) and (max-width: 2048px){
    .vidCol{
        width:33% !important
    }
    .videoCardContainer{
    height: auto;
    }
}
