.dropContainer{
    box-sizing: border-box;
    flex-direction: row;
    margin: auto;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    width: 70%;
    display: flex;
    border-radius: 5px;
    padding: 40px;
}

.drop{
    background-color: rgb(249, 250, 251) !important;
    border: 2px solid lightgrey;
    color: #9a9191;
    padding: 10px 20px;
    border-radius: 5px;
}
.dropCard{
    box-shadow: rgb(145 158 171 / 20%) 0px 3px 1px -2px, rgb(145 158 171 / 14%) 0px 2px 2px 0px, rgb(145 158 171 / 12%) 0px 1px 5px 0px;
    margin-bottom: 20px !important;
    background-color: rgb(249, 250, 251) !important;
}
.drop button{
    background: transparent;
    border: none;
    color: rgb(53, 52, 52);
}

.dropContainer button#dropdown-basic{
    display: block;
    width: 100%;
}

.dropContainer .dropdown-toggle::after{
    float: right;
    margin-top: 10px;
}

.drop button:hover{
    background: transparent !important;
    border: none;
    color: rgb(53, 52, 52);
}
.inputDrop{
    background: rgb(249, 250, 251)!important;
    padding: 15px  15px !important;
    border: 2px solid lightgrey !important;
}
.addBtn{
    /* margin-top: 2px; */
    padding: 15px 15px !important;
    border-radius: 5px;
}
.addDropMenu{
    width: 20rem;
     background: rgb(249, 250, 251);
}

@media only screen and (min-width: 768px) {
    .addDropMenu{
        width: 100%;
         background: rgb(249, 250, 251);
    }
    .addBtn{
        padding: 7px 10px !important;
        /* align-self: center; */
        margin-top: 8px;
    }
    .drop{
        padding: 10px 5px;
    }
  }
