.div-container {
  width: 100%;
  justify-content: center;
  align-items: center;
}

.row-Container {
  height: 100%;
  justify-content: space-between;
  display: "flex";
}

.loginCardContainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginCardContainer1 {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-card {
  width: 70%;
  padding-top: 20px;
  padding-bottom: 20px;
  border: none !important;
  background-color: transparent !important;
}

.login-card1 {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  border: none !important;
  box-shadow: rgb(145 158 171 / 20%) 0px 3px 1px -2px, rgb(145 158 171 / 14%) 0px 2px 2px 0px, rgb(145 158 171 / 12%) 0px 1px 5px 0px;
  padding: 70px 0;
}

.login-image {
  width: 100%
}

.loginLogo {
  width: 30%;
  justify-content: center;
  display: flex;
  position: relative;
  top: 40%;
  left: 30%;
}

.loginLogo1 {
  width: 100%;
}

.Login-inputFields {
  margin-top: 10px;
  width: 100% !important;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #CCDDE8 !important;
}

.heading1 {
  color: #000;
  text-align: left;
  margin-top: 20px;
  font-size: 1.5rem;
  font-weight: 700px !important;
}

.headingPara {
  margin: 0px 0px 40px;
  line-height: 1.5;
  font-size: 1rem;
  font-family: "Public Sans", sans-serif;
  font-weight: 400;
  color: rgb(99, 115, 129);
}

.passwordContainer {
  display: flex;
  position: relative;
}

.passwordContainer svg {
  position: absolute;
  right: 9px;
  top: 25px;
  cursor: pointer;
  font-size: larger;
}
/* @media screen and (max-width:1439px ) and (min-width: 1025px) {

} */
@media only screen and (min-width:1792px) and (max-width: 2048px){
  .loginCardContainer1{
    width : 40% !important;
  }
}