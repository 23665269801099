.complaintCard {
    width: 100%;
  }

  .complaintCard .nav-item button{font-weight: 500; color:#000}
  .complaintCard .nav-item button.nav-link.active{color:#f00}

  .judgeCard{
    width: 100%;
    box-shadow: 2px 2px 4px #ddd;
    margin-bottom: 20px !important;
    /* overflow: scroll; */
  }
  .lawyerCard{
    width: 100%;
    box-shadow: 2px 2px 4px #ddd;
    margin-bottom: 20px !important;

  }
  .policeCard{
    width: 100%;
    box-shadow: 2px 2px 4px #ddd;
    margin-bottom: 20px !important;
  }

  /* .table tr{
    max-width: 30%;
    white-space: break-spaces;
    text-overflow: ellipsis;
    display: -webkit-box; 
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  } */

  .actiontd{
    justify-content:space-between;
    /* display:flex; */
  }

  .judgeCard table tr th:nth-child(5), .judgeCard table tr th:nth-child(3) {
    width: 110px;
}

.lawyerCard table tr th:nth-child(3), .lawyerCard table tr th:nth-child(6), .lawyerCard table tr th:nth-child(5){
  width: 100px;
}

.policeCard table tr th:nth-child(3),.policeCard table tr th:nth-child(4), .policeCard table tr th:nth-child(6){
  width: 100px;
}


@media only screen and (min-width: 768px) {
  .tableHeader{
    font-size: small;
    font-weight:600;
  }
  .card-body table tbody tr td{
    font-size: small;
  }
  .actiontd button{
    padding: 5px;
    font-size: 11px;
  }
  td.actiontd span {
    font-size: 12px !important;
    padding-bottom: 4px;
}
}

@media only screen and (min-width: 1024px) {
  .tableHeader{
    font-size: small;
    font-weight:700;
  }
  .approveBtn{
    margin-top: 20px;
  }
}