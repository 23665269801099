.fileCard {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    /* border: none !important; */
    box-shadow: rgb(145 158 171 / 20%) 0px 3px 1px -2px, rgb(145 158 171 / 14%) 0px 2px 2px 0px, rgb(145 158 171 / 12%) 0px 1px 5px 0px;
    padding: 70px 0;
  }
  
  .fileCard .nav-item button{font-weight: 500; color:#000}
  .fileCard .nav-item button.nav-link.active{color:#f00}

  @media screen and (max-width: 1439px) and (min-width: 1025px){
.fileCard {
    height: 590px !important
  }
  }

  @media only screen and (min-width: 1440px){
    .fileCard{
        height: 700px !important;
    }
}