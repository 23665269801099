.totalUsers svg{
font-size:80px;
color: #148fd6;
}
.totalUsers .card-title {
    font-size:25px;
}
.totalUsers .card-text{
    font-size:25px;
    color: #148fd6;
    font-weight: 600;
}