.streamCard {
    box-shadow: rgb(145 158 171 / 20%) 0px 3px 1px -2px, rgb(145 158 171 / 14%) 0px 2px 2px 0px, rgb(145 158 171 / 12%) 0px 1px 5px 0px;
    margin-bottom: 20px !important;
    background-color: #fff !important;
}


@media screen and (max-width:1365px ) and (min-width: 1024px) {
    .liveVideo{
        width: 35.4%;
    /* height: 45%; */
    overflow: hidden;
        }
         svg.closeIcon{
            position: absolute;
    right: 0;
    top: 0;
        }
}

@media screen and (max-width:1439px ) and (min-width: 1366px){
    .liveVideo{
        width: 27%;
    /* height: 45%; */
    overflow: hidden;
        }
         svg.closeIcon{
            position: absolute;
    right: 0;
    top: 0;
        }
}

@media screen and (min-width:1440px){
    .liveVideo{
        width: 35%;
    /* height: 45%; */
    overflow: hidden;
        }
         svg.closeIcon{
            position: absolute;
    right: 0;
    top: 0;
        }
}


@media screen and (max-width:2048px ) and (min-width: 1441px){
    .liveVideo{
        width: 34.2%;
    /* height: 45%; */
    overflow: hidden;
        }
         svg.closeIcon{
            position: absolute;
    right: 0;
    top: 0;
        }
}




