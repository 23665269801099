.subsTableCard{
    width: 100%;
    box-shadow: 2px 2px 4px #ddd;
    margin-bottom: 20px !important;
}

@media only screen and (min-width: 768px) {
    .subsTableCard{
        width:50%;
    }
    .cardContainer{
        justify-content: center;
        display: flex;
    }
}

@media only screen and (min-width: 768px){
    .subsTableCard{
        width:100%;
    }
}