.titleDetails{
    box-shadow: rgb(145 158 171 / 20%) 0px 3px 1px -2px, rgb(145 158 171 / 14%) 0px 2px 2px 0px, rgb(145 158 171 / 12%) 0px 1px 5px 0px;
color : #595252
}

.labelPara{
    margin-bottom: 0rem !important;
    margin-top: 10px !important;
    font-weight: 500;
}
