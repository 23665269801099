.appLogoBar{
    height: auto;
    padding-top: 15px;
    padding-bottom: 24px;
     box-shadow: -5px 7px 4px 0px #dddddd57 !important 
    /* border-bottom: 1px solid #ddd; */
}
.detailsHeading{
    color:rgb(151 140 140);
    text-align:center;
    word-break: break-all;
    padding: 0 10px;
    /* padding-left: 35px; */
}

.changePassword{
  color:rgb(151 140 140);
  text-align:center;
  font-size: 14px;
  cursor: pointer;
}

.settingsDrop{
    margin-top: 20px;
    width: 100%;
    text-align: left !important;
    background: transparent !important;
    border: none !important;
    color: #000 !important;
}
.logoutBtn{
    margin-top: 10px;
    width:100%;
    background-color: transparent !important;
    color: red !important;
    border: none !important;
    text-align: left !important;
}
.menuListItem {
    width: 100%;
    /* color:  #697a8d!important; */
    border: none !important;
    /* background-color: transparent !important; */
    margin-top: 5px;
}

.menuListItem:hover {
    background-color: #CCDDE8;
    color:"#000 !important";
    cursor: pointer;
}
.menuListItem:hover .menuListItem{
    color: #000 !important;
}
/* width */
.sidebar-container ::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
 .sidebar-container ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    /* border-radius: 10px; */
  }
  
  /* Handle */
  .sidebar-container ::-webkit-scrollbar-thumb {
    /* background-image: linear-gradient(to left, rgba(2, 16, 63, 0), rgb(113, 32, 189)); */
    background-Color: rgb(126, 124, 124);
    /* border-radius: 10px; */
  }  

  @media screen and (max-width:1439px ) and (min-width: 1024px){
    
    
      
      .sidebar-container::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #F5F5F5;
      }
      
      .sidebar-container::-webkit-scrollbar {
        width: 5px;
        background-color: #F5F5F5;
      }
      
      .sidebar-container::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        background-color: #555;
      }
    /* .menuListContainer{
        max-height: 500px !important;
        padding: 0 5px 50px 5px !important;
        overflow-y: auto !important;

    } */
   .sidebar-container {padding-right: 0px;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;}
  }
  /* @media only screen and (min-width : 768px){
     .menuListContainer{
        max-height: 600px;
        padding: 0 5px 50px 5px;
        overflow-y: auto;
    } 
  }*/ 