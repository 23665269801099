.audioCard{
    box-shadow: rgb(145 158 171 / 20%) 0px 3px 1px -2px, rgb(145 158 171 / 14%) 0px 2px 2px 0px, rgb(145 158 171 / 12%) 0px 1px 5px 0px;
    margin-bottom: 20px !important;
}

.audioCardBtn{
    border: none;
    background-color:green !important; 
    color: #fff;
}

.audioCardBtn:hover{
    border: none;
    background-color:green !important; 
    color: #fff; 
}
.audioCardBtn:active{
    border: none;
    background-color:green !important; 
    color: #fff;
}

@media only screen and (min-width: 768px) {
    .audioCardContainer{
        justify-content: center;
        overflow-y: scroll;
        height: 500px;
    }
}

@media only screen and (min-width : 1024px){
    .audioCardContainer{
        justify-content: center;
        overflow-y: scroll;
        height: 490px;
    }
}
@media screen and (max-width:1439px ) and (min-width: 1025px) {
    .audioCardContainer{
        justify-content: center;
        overflow-y: auto;
        height: 400px;
    }
    .audCol{
        width:33% !important
    }
  }

@media only screen and (min-width: 1440px){
    .audioCardContainer{
        justify-content: center;
        overflow-y: scroll;
        height: 500px;
    }
}

@media only screen and (min-width:1792px) and (max-width: 2048px){
    .audCol{
        width:33% !important
    }
    .audioCardContainer{
    height: auto;
    }
}