.container {
  max-width: 100% !important;
}

.nav-container {
  padding: 20px
}

.main-container {
  margin-top: 30px !important;
  flex-direction: column;
  width: 100% !important;
  min-width: 0;
  max-width: 100%;
  padding: 0 2rem 0 16rem !important;
}



.sidebar-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  margin-right: 0 !important;
  margin-left: 0 !important;
  padding-left: 0px !important;
  /* background-color: #151528 !important; */
  background-color: #fff;
  box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%);
  /* border: 2px Solid #f8c537; */
  border-top: none;
  /* min-height: 100vh; */
}
@media screen and (max-width:991px ) and (min-width: 800px) {
  .main-container{
    padding: 0 2rem 0 16rem !important;
  }
}
/* @media only screen and (min-width: 768px) {
  .main-container{
    padding: 0 2rem 0 13rem !important;
  }
} */
@media screen and (max-width:1439px ) and (min-width: 1024px) {
  .main-container{
    padding: 0 2rem 0 16rem !important;
  }
}
@media only screen and (min-width: 1440px){
  .main-container{
    padding: 0 2rem 0 18rem !important;
  }
}
@media only screen and (min-width:1792px) and (max-width: 2048px){
  .main-container{
    padding: 0 2rem 0 21rem !important;
  }
}